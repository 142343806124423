import React from "react";
import "./Paragraph.css";
import { useRef } from "react";
import { useInView } from "framer-motion";

const Paragraph = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <section className="para-mian">
      <div className="para-container">
    <div className="">
      <section ref={ref} style={{ display: "flex", justifyContent: "center"}}>
        <span
          style={{textAlign: 'center',
            transform: isInView ? "translateX(0px)" : "translateX(-200px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
        <p className="paras"> AcademiaReach Pvt Ltd is a forward-thinking institution established with a singular purpose - to advance education across various disciplines and fields of study. We are dedicated to nurturing the minds of tomorrow and
          fostering a culture of continuous learning, innovation, and excellence.</p>
        </span>
      </section>
    </div>
       </div>
    </section>
  );
};

export default Paragraph;
